function About() {

    return (
        <section id="about-section">
            <h2 className="h2-about">About</h2>
            <p className="p-summary">"A broad-minded, versatile philosophy and industrial engineering graduate. Capable of combining qualitative and quantitative approaches for complex, interdisciplinary tasks. Adept at knowledge transfer and tool adaptation in multicultural settings."</p>
            <p className="p-about">Hi, I’m Tunahan, or simply Tuna.</p>
            <p className="p-about">I don’t know what I am. I'm not sure if it is possible. I would be, under normal circumstances, an industrial engineer. But, normal is rare. I have so far divided my interest among many areas. That’s how I exist. Otherwise, I end up in frustration.</p>
            <p className="p-about">While studying, I took and audited countless courses from different schools and departments ranging from economics to anthropology, and minored in philosophy. Simultaneously, I carried out many small design and development projects, because, well, I also love building things.</p>
            <p className="p-about">Soon after, I began working as an IT specialist. However, philosophy was haunting me. Then, I started a freelance life and pursuing a philosophy major at another school in another paradigm.</p>
            <p className="p-about">What I like, and what I am good at, whatever the problem at hand is, are comprehending the essentials, transferring the knowledge between various disciplines, and when it is time to build something solid, synthesizing my inventory of skills and appropriating necessary tools.</p>
            <p className="p-about">Naturally; versatility, agility, broad-mindedness and flexibility are some of the things that I bring to the table. I prefer appreciating complexity as I find it organic.</p>
            <p className="p-about">This is what I have been for a long time. Philosophy was, is and will be central to my life. In some particular way, I try to find my place in this world. I read, I learn, I write, I run, I work.</p>
            <p className="p-about">Below is a rather simplified demonstration of my past. Some details or some entire parts are left out to make room. Boring you would be against the purpose of this website. You can find out more by contacting me.</p>
        </section>
    )
}

export default About
